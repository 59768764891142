@font-face {font-family: "icon";
  src: url('icon.eot?t=1740075971498'); /* IE9*/
  src: url('icon.eot?t=1740075971498#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("icon.woff2?t=1740075971498") format("woff2"),
  url("icon.woff?t=1740075971498") format("woff"),
  url('icon.ttf?t=1740075971498') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('icon.svg?t=1740075971498#icon') format('svg'); /* iOS 4.1- */
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icon' !important;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before { content: "\ea01"; }
.icon-addwords:before { content: "\ea02"; }
.icon-admin-alt:before { content: "\ea03"; }
.icon-angle-regular-down:before { content: "\ea04"; }
.icon-angle-regular-up:before { content: "\ea05"; }
.icon-book:before { content: "\ea06"; }
.icon-chat-arrow-grow:before { content: "\ea07"; }
.icon-checkbox:before { content: "\ea08"; }
.icon-clear:before { content: "\ea09"; }
.icon-cookie:before { content: "\ea0a"; }
.icon-cross:before { content: "\ea0b"; }
.icon-customer-service:before { content: "\ea0c"; }
.icon-dashboard:before { content: "\ea0d"; }
.icon-data-transfer:before { content: "\ea0e"; }
.icon-document-circle-wrong:before { content: "\ea0f"; }
.icon-it-computer:before { content: "\ea10"; }
.icon-keyboard:before { content: "\ea11"; }
.icon-lock-open-alt:before { content: "\ea12"; }
.icon-marketplace-alt:before { content: "\ea13"; }
.icon-megaphone:before { content: "\ea14"; }
.icon-menu-burger:before { content: "\ea15"; }
.icon-message-sms:before { content: "\ea16"; }
.icon-model-cube-arrows:before { content: "\ea17"; }
.icon-onboarding:before { content: "\ea18"; }
.icon-paper-plane:before { content: "\ea19"; }
.icon-phone-call:before { content: "\ea1a"; }
.icon-shield-check:before { content: "\ea1b"; }
.icon-square-plus:before { content: "\ea1c"; }
.icon-team:before { content: "\ea1d"; }
.icon-triangle-warning:before { content: "\ea1e"; }
.icon-url:before { content: "\ea1f"; }
.icon-users-alt:before { content: "\ea20"; }
.icon-users:before { content: "\ea21"; }

$icon-add: "\ea01";
$icon-addwords: "\ea02";
$icon-admin-alt: "\ea03";
$icon-angle-regular-down: "\ea04";
$icon-angle-regular-up: "\ea05";
$icon-book: "\ea06";
$icon-chat-arrow-grow: "\ea07";
$icon-checkbox: "\ea08";
$icon-clear: "\ea09";
$icon-cookie: "\ea0a";
$icon-cross: "\ea0b";
$icon-customer-service: "\ea0c";
$icon-dashboard: "\ea0d";
$icon-data-transfer: "\ea0e";
$icon-document-circle-wrong: "\ea0f";
$icon-it-computer: "\ea10";
$icon-keyboard: "\ea11";
$icon-lock-open-alt: "\ea12";
$icon-marketplace-alt: "\ea13";
$icon-megaphone: "\ea14";
$icon-menu-burger: "\ea15";
$icon-message-sms: "\ea16";
$icon-model-cube-arrows: "\ea17";
$icon-onboarding: "\ea18";
$icon-paper-plane: "\ea19";
$icon-phone-call: "\ea1a";
$icon-shield-check: "\ea1b";
$icon-square-plus: "\ea1c";
$icon-team: "\ea1d";
$icon-triangle-warning: "\ea1e";
$icon-url: "\ea1f";
$icon-users-alt: "\ea20";
$icon-users: "\ea21";

